<template>
  <div :class="isPC() ? 'pc' : 'mobile'" class="container">
    <div class="content">
      <div class="left">
        <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/logo-02%402x.png"></el-image>
        <div class="next">
          <div class="wechat">
            <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E7%BC%96%E7%BB%84%2011%402x.png"
                      @mouseenter="wechatFlag = true"
                      @mouseleave="wechatFlag = false"></el-image>
            <div v-if="wechatFlag" class="qrCode">
              <el-image src="https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/SHS/2024/web/images/pic/gongzhonghao.png"></el-image>
            </div>
          </div>
          <div class="miniProgram">
            <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E7%BC%96%E7%BB%84%2010%402x.png"
                      @mouseenter="miniProgramFlag = true"
                      @mouseleave="miniProgramFlag = false"></el-image>
            <div v-if="miniProgramFlag" class="qrCode">
              <el-image src="https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/SHS/2024/web/images/pic/xiaochengxu.png"></el-image>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="title">{{ $t('contactUs') }}</div>
        <div class="name">{{ $t('footerName') }}</div>
        <div class="contact">
          <div class="local">
            <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E5%BD%A2%E7%8A%B6%402x.png"></el-image>
            {{ $t('footerLocal') }}
          </div>
          <div class="mail">
            <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/youjian%402x.png"></el-image>
            {{ $t('footerMail') }}
          </div>
          <div class="phone">
            <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/dianhua%402x.png"></el-image>
            {{ $t('footerPhone') }}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">
          {{ $t('footerPartner') }}
          <el-image
            src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E5%BD%A2%E7%8A%B6%E7%BB%93%E5%90%88%E5%A4%87%E4%BB%BD%202%402x.png"
            @click="scrollToTop"></el-image>
        </div>
        <el-image src="https://iscfiles.oss-accelerate-overseas.aliyuncs.com/tmp/SHS/2024/web/images/%E7%BC%96%E7%BB%84%402x(3).png"></el-image>
      </div>

    </div>
    <div class="bottomLine"></div>
    <div class="copyRight">
      Copyright © Global Exhibition CEO Shanghai Summit
      <br>
      <a href='https://beian.miit.gov.cn/' target='_blank'>沪ICP备2023035980号-1</a>
    </div>
  </div>
</template>

<script>
import {isPC} from "@/utils";

export default {
  name: '',
  data() {
    return {
      wechatFlag: false,
      miniProgramFlag: false
    }
  },
  created() {
  },
  methods: {
    isPC,
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  max-width: 1920px;
  height: 413px;
  background: #0E0C15;

  .content {
    width: 1310px;
    margin: 0 auto;
    display: flex;
    color: #A4A6BD;

    > div {
      flex: 1;
      margin-top: 44px;
    }

    .left {
      > .el-image {
        width: 337px;
      }

      .next {
        margin-top: 23px;
        width: 337px;
        text-align: right;

        .wechat {
          margin-right: 20px;
          display: inline-block;
          position: relative;

          > .el-image {
            width: 40px;
            cursor: pointer;
          }
        }

        .miniProgram {
          display: inline-block;
          position: relative;

          > .el-image {
            width: 40px;
            cursor: pointer;
          }
        }

        .qrCode {
          width: 150px;
          position: absolute;
          z-index: 999;
          top: 56px;
          left: -115px;

        }

      }
    }

    .middle {
      padding: 0 26px;

      .title {
        font-size: 18px;
        margin-bottom: 42px;
        display: block;

        &:after {
          display: none;
        }
      }

      .name {
        margin-bottom: 20px;
      }

      .contact {
        .local, .mail, .phone {
          margin-bottom: 20px;
          display: flex;
          align-items: center;

          .el-image {
            width: 18px;
            margin-right: 9px;
          }
        }
      }
    }

    .right {
      .title {
        font-size: 18px;
        display: block;

        &:after {
          display: none;
        }

        .el-image {
          width: 36px;
          float: right;
          cursor: pointer;
        }
      }


      > .el-image {
        margin-top: 39px;
        width: 144px;
      }
    }
  }

  .bottomLine {
    width: 1309px;
    height: 2px;
    margin: 41px auto;
    background: linear-gradient(90deg, #BFFCBA 0%, #5FB7F1 32%, #E35BFD 72%, #F86A4B 100%);
  }

  .copyRight {
    font-weight: bold;
    color: #A4A6BD;
    font-size: 12px;
    line-height: 17px;
    text-align: center;

    > a {
      color: #A4A6BD;
      text-decoration: none;
    }
  }
}

.mobile {
  height: auto;

  .content {
    flex-direction: column;
    width: 100%;

    > div {
      padding: 0 26px;
    }
  }

  .bottomLine {
    width: 85%;
  }

  .copyRight {
    padding-bottom: 35px;
  }
}
</style>
